import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { Router } from '@reach/router';

import { Container } from '@material-ui/core';
import {
  AssignmentOutlined,
  CreateNewFolderTwoTone,
  OpenInNew,
  PersonAdd,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { GET_RECENT_MEDICAL_CASES } from '../graphql/queries';
import { PageListContent } from './Common/styled/PageContent';
import { CardWrapperUI } from './Common/styled/CardWrapperUI';
import { SectionBar } from '../componentsUI/SectionBar';

import Loading from '../components/Common/Loading';
import CaseDetails from '../pages/case';
import { Span } from './Common/styled/Text';
import TableCollapse from '../componentsUI/tableCollapse';
import ItemCollapse from '../componentsUI/itemCollapse';
import { Navbar } from './Navbar/styled/NavbarStyles';
import { ProtocolAddDialog } from './Protocols/ProtocolAddDialog';
import { ContainerUI } from './Common/styled/ContainerUI';
import { AlertWrapperUI, GraphQLAlertUI } from '../componentsUI/Alert';
import { networkErrorParse } from '../utils/ErrorGraphQLUtils';

const fieldNames = [
  { label: 'medical.case', id: 'node.title', width: 240 },
  { label: 'first.name', id: 'node.patient.name', width: 90 },
  { label: 'last.name', id: 'node.patient.surname', width: 110 },
  { label: 'case.created', id: 'node.createdAt', width: 120, format: 'DATEFORMAT', align: 'center' },
];

export const DashboardView = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [expanded, setExpanded] = useState(-1);
  const [showProtocolModal, setShowProtocolModal] = useState(false);
  const hospital = useSelector((state) => state.hospital);

  const { loading: mcLoading, error: mcError, data: mcData, refetch: mcRefetch } = useQuery(
    GET_RECENT_MEDICAL_CASES,
    {
      variables: {
        hospitalUuid: hospital.uuid,
        first: 20,
        orderBy: {
          field: 'CREATED_AT',
          direction: 'DESC',
        },
      },
    },
  );

  const medicalCase = (mcData && mcData.medicalCases && selected.length > 0)
    && mcData.medicalCases.edges[selected[0]] && mcData.medicalCases.edges[selected[0]].node;

  const handleOpen = (evt) => {
    evt.stopPropagation();
    navigate(`/case/${medicalCase.uuid}`);
  };

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const iMedicalCase = mcData.medicalCases.edges[index].node;
    navigate(`/case/${iMedicalCase.uuid}`);
  };

  const handleCloseProtocolModal = () => {
    setShowProtocolModal(false);
  };

  const errorMessage = networkErrorParse(mcError);

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <GraphQLAlertUI severity="error" title="Error" refetch={mcRefetch}>{t(errorMessage)}</GraphQLAlertUI>
      </AlertWrapperUI>
    );
  }

  const buttons = [
    { name: 'new.patient', icon: PersonAdd, handleClick: () => { navigate('/patients/new'); }, hidden: !hospital.menuOptionPatients },
    { name: 'new.case', icon: CreateNewFolderTwoTone, handleClick: () => { navigate('/cases/new'); }, hidden: !hospital.menuOptionMedicalCases },
    { name: 'new.protocol', icon: AssignmentOutlined, handleClick: () => { setShowProtocolModal(true); }, hidden: !hospital.menuOptionProtocols },
    { name: 'divider', type: 'divider' },
    { name: 'view.case', icon: OpenInNew, handleClick: handleOpen, disabled: selected.length !== 1 },
    { name: 'divider', type: 'divider' },
  ];

  return (
    <ContainerUI>
      <Navbar>
        <SectionBar title="recent.cases" items={buttons} />
      </Navbar>
      <ProtocolAddDialog open={showProtocolModal} onClose={handleCloseProtocolModal} />
      <Container maxWidth="lg" className="article">
        <PageListContent>
          {
            mcLoading
              ? <Loading />
              : (
                <CardWrapperUI>
                  {
                    (!!mcData.medicalCases && mcData.medicalCases.edges.length > 0)
                      ? (
                        <TableCollapse
                          fieldNames={fieldNames}
                          items={mcData.medicalCases.edges}
                          GetTitle={(item) => `${item.node.title}`}
                          GetSubtitle={(item) => `${item.node.patient.name} ${item.node.patient.surname} - ${item.node.createdAt}`}
                          GetCollapse={ItemCollapse}
                          selected={selected}
                          setSelected={setSelected}
                          handleGoto={handleGoto}
                          expanded={expanded}
                          setExpanded={setExpanded}
                        />
                      )
                      : <Span>{t('no.recent.cases')}</Span>
                  }
                </CardWrapperUI>
              )
          }
        </PageListContent>

        <Router>
          <CaseDetails path="/case/:id" />
        </Router>
      </Container>
    </ContainerUI>
  );
};
