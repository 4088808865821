import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { navigate } from 'gatsby';
import InfoAccordion from '../../../componentsUI/InfoAccordion';
import { Span } from '../../Common/styled/Text';
import { VirtualVisitCard } from '../../Common/VirtualVisitCard/VirtualVisitCard';
import { getFormattedDate } from '../../../utils/dateTimeUtils';
import ArchivesTableCollapse from '../../../componentsUI/ArchivesTableCollapse';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0 2em 1em',
  },
  image: {
    maxHeight: 32,
  },
  title: {
    'div[class*="itemSelected"] &': {
      fontWeight: 700,
    },
  },
  subtitle: {
    margin: '0 .375em',
    color: theme.palette.grey.A200,
    '& > p': {
      fontSize: '0.875em',
      marginRight: 8,
    },
    '& $patient': {
      fontSize: '1em',
    },
  },
  patient: {
    fontWeight: 700,
    color: theme.palette.primary.light,
    'div[class*="itemSelected"] &': {
      color: theme.palette.primary.main,
    },
  },
  uuid: {
    'div[class*="itemSelected"] &': {
      color: theme.palette.grey.A700,
    },
  },
}));

export const CaseVirtualVisits = ({ medicalCase, virtualVisits }) => {
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();
  const styles = useStyles();

  const getTitle = (item) => (
    <Box className={styles.title}>
      {item && getFormattedDate({ date: item.appointment, format: 'weekdayComma' })}
    </Box>
  );

  const getSubTitle = (item) => (
    <Box className={styles.subtitle}>
      <Typography className={styles.patient} display="inline">{`${item.patient.name} ${item.patient.surname}`}</Typography>
      <Typography className={styles.uuid} display="inline">{item.uuid}</Typography>
    </Box>
  );

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const virtualVisit = virtualVisits[index];

    navigate(`/virtual-visit/${virtualVisit.uuid}`);
  };

  const emptyList = !virtualVisits || !virtualVisits.length;

  const VirtualVisitsList = () => {
    if (emptyList) return <Box style={{ margin: '.5em 1em' }}>{t('no.virtual.visits')}</Box>;

    return (
      <ArchivesTableCollapse
        responsive={false}
        fieldNames={null}
        items={virtualVisits}
        GetTitle={getTitle}
        GetSubtitle={getSubTitle}
        selected={selected}
        setSelected={setSelected}
        handleGoto={handleGoto}
      />
    );
  };

  return <InfoAccordion title={t('virtual.visits')} Item={VirtualVisitsList} />;
};

export default CaseVirtualVisits;
