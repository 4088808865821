import React from 'react';
import { Link } from 'gatsby';

import moment from 'moment-timezone';
import IcomoonReact from 'icomoon-react';
import icons from '../../../icons/cloud.json';

import { useCardStyles } from '../styled/Card';
import { Column, Row } from '../styled/Groups';

export default ({ medicalCaseUuid, study }) => {
  const formattedDate = study.createdAt && moment(study.createdAt).utc().format('DD[/]MM[/]YYYY');
  const formattedModalities = study.modalities.join('/');
  const classes = useCardStyles();

  return (
    <div className={classes.container}>
      <Link to={`/study/${study.uuid}`} state={{ medicalCase: { uuid: medicalCaseUuid } }}>
        <div className={classes.wrapper}>
          <div>
            <IcomoonReact iconSet={icons} color="default" size={25} icon="file_DICOM" />
          </div>
          <Row>
            <Column>
              <p className={classes.label}>{study.title}</p>
              <p className={classes.date}>
                {`${formattedDate} · ${study.numberOfSeries} Series · ${study.numberOfInstances} Images · ${formattedModalities}`}
              </p>
            </Column>
          </Row>
        </div>
      </Link>
    </div>
  );
};
