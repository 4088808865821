import React, { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { alpha, makeStyles } from '@material-ui/core/styles';

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loading from '../../Common/Loading';
import { isEmpty } from '../../../utils/ObjectUtils';
import {
  GET_MEDICAL_CASE_FOR_EDITING,
  GET_MEDICAL_CASE_FOR_PREVIEW,
} from '../../../graphql/queries';
import { UPDATE_MEDICAL_CASE } from '../../../graphql/mutations';
import { CaseForm } from '../CaseForm';
import { AlertUI } from '../../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  submit: {
    minWidth: 200,
    float: 'right',
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const EditCaseDialog = ({ caseId, open, onClose }) => {
  if (!caseId) return null;

  const { t } = useTranslation();
  const classes = useStyles();

  const { loading, data } = useQuery(
    GET_MEDICAL_CASE_FOR_PREVIEW,
    {
      variables: {
        uuid: caseId,
      },
    },
  );

  const [serverError, setServerError] = useState(false);
  const [updateMedicalCase, { loading: updating }] = useMutation(UPDATE_MEDICAL_CASE, {
    onCompleted() {
      onClose();
    },
    refetchQueries: [{
      query: GET_MEDICAL_CASE_FOR_EDITING,
      variables: {
        uuid: caseId,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }],
    onError: (error) => {
      if (error && error.graphQLErrors && error.graphQLErrors.length) {
        setServerError(error.graphQLErrors[0].message);
      }
    },
  });

  const goBack = () => window.history.back();

  const submitAction = async (values) => {
    updateMedicalCase({
      variables: {
        medicalCase: {
          uuid: caseId,
          title: values.title,
          specialty: values.specialty,
          subspecialty: values.subspecialty,
          active: values.active === 'true',
          description: values.description,
        },
      },
    }).then();
  };

  const patientInfo = data && data.medicalCase && data.medicalCase.patient
    && `${data.medicalCase.patient.name} ${data.medicalCase.patient.surname}`;

  const initialValues = data && data.medicalCase && {
    patient: patientInfo,
    title: data.medicalCase.title,
    specialty: data.medicalCase.specialty,
    subspecialty: data.medicalCase.subspecialty,
    active: data.medicalCase.active === 0 ? 'false' : 'true',
    description: data.medicalCase.description,
    // share: '',
    // sharePermission: 'view',
  };

  const showServerError = () => {
    const message = serverError.includes('you.are.not.allowed.to.perform.this.action')
      ? t('not.allowed.to.perform.action')
      : t('server.error');
    return <AlertUI severity="error" title="Error">{message}</AlertUI>;
  };

  const buttons = [
    { classes: classes.button, color: 'primary', variant: 'outlined', onClick: onClose, label: t('cancel') },
    { classes: classes.button, color: 'primary', variant: 'contained', type: 'submit', form: 'edit-case-form', label: t('update') },
  ];

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('edit.case')}</DialogTitle>
      <DialogContent>
        {(loading || isEmpty(data)) ? (
          <Loading />
        ) : (
          <CaseForm
            formId="edit-case-form"
            initialValues={initialValues}
            buttons={buttons}
            submitAction={submitAction}
            serverError={serverError}
            setServerError={setServerError}
            submitting={updating}
            onClose={goBack}
            showServerError={showServerError}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
