export const imageExtensions = ['jpg', 'gif', 'png', 'jpeg', 'svg'];

export const getUrlExtension = (urlString) => {
  try {
    const url = new URL(urlString);
    return url.pathname.substr(url.pathname.lastIndexOf('.') + 1).toLowerCase();
  } catch (error) {
    return null;
  }
};

export const getFilenameExtension = (filename) => {
  try {
    return filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
  } catch (error) {
    return null;
  }
};
