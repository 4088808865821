import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { BasicFileCardList } from '../Common/BasicFileCard/BasicFileCard';
import { Translate } from '../Common/Translation/Translate';
import StudyCardDetails from '../Common/StudyCard/StudyCardDetails';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '0 2em 1em',
  },
  studies: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'left',
    maxWidth: '540px',
  },
  filesUl: {
    display: 'block',
    width: '200px',
    flexDirection: 'column',
    paddingLeft: '1em',
    margin: '1em 0 .125em',
    fontSize: '.8375em',
    '& > li': {
      marginBottom: '.25em',
      width: 'calc(100vw - 450px)',
      minWidth: '250px',
      color: '#909090',
    },
  },
  noItem: {
    margin: '.25em',
    fontSize: '.875em',
    color: '#909090',
  },
}));

const NoItem = ({ entity }) => {
  const classes = useStyles();
  const text = entity === 'study' ? 'case.no.studies' : 'no.files';
  return (
    <div className={classes.noItem}>
      <Translate text={text} />
    </div>
  );
};

const StudyList = ({ medicalCase }) => {
  const classes = useStyles();
  return (
    <div className={classes.studies}>
      {medicalCase.studies.map((study) => (
        <StudyCardDetails
          medicalCaseUuid={medicalCase.uuid}
          study={study}
          key={study.uuid}
        />
      ))}
    </div>
  );
};

const FilesList = ({ medicalCase }) => {
  const classes = useStyles();
  return (
    <ul className={classes.filesUl}>
      {medicalCase.medicalCaseFiles.map((file) => (
        <BasicFileCardList
          medicalCase={medicalCase}
          file={file}
          key={file.uuid}
        />
      ))}
    </ul>
  );
};

const StudyInfo = ({ medicalCase }) => (
  !!medicalCase.studies && !!medicalCase.studies.length
    ? <StudyList medicalCase={medicalCase} />
    : <NoItem entity="study" />
);

const FilesInfo = ({ medicalCase }) => (
  !!medicalCase.medicalCaseFiles && !!medicalCase.medicalCaseFiles.length
    ? <FilesList medicalCase={medicalCase} />
    : <NoItem entity="file" />
);

export const CaseCollapse = ({ item }) => {
  const medicalCase = item.node ? item.node : item;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <StudyInfo medicalCase={medicalCase} />
      <FilesInfo medicalCase={medicalCase} />
    </div>
  );
};

export default CaseCollapse;
