import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoAccordion from '../../componentsUI/InfoAccordion';

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    flexGrow: 1,
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  xxs5: {
    '@media (max-width:600px)': {
      maxWidth: '41.666666%',
      flexBasis: '41.666666%',
    },
    '@media (max-width:450px)': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  xxs7: {
    '@media (max-width:600px)': {
      maxWidth: '58.333333%',
      flexBasis: '58.333333%',
    },
    '@media (max-width:450px)': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  accordion: {
    '& .MuiAccordionSummary-root': {
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
      '& p': {
        padding: '.3125em 0',
        lineHeight: 1.25,
      },
    },
    '& .Mui-expanded .MuiAccordionSummary-root': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  list: {
    paddingInlineStart: 30,
    paddingInlineEnd: 8,
    fontSize: '.9em',
    color: theme.palette.grey.A700,
  },
  noItems: {
    marginLeft: '.375em',
    padding: 8,
    color: theme.palette.grey.A200,
  },
}));

export default ({ patient }) => {
  const { t } = useTranslation();
  const classes = useStyles({ color: true });

  const PatientSecondaryInfoList = ({ list, label }) => (
    <Grid item xs={12}>
      {list && list.length > 0 ? (
        <ul className={classes.list}>
          {list.map((item, i) => (
            <li key={i.toString()}>{t(item)}</li>
          ))}
        </ul>
      ) : (
        <div className={classes.noItems}>{t(`no.${label}`)}</div>
      )}
    </Grid>
  );
  const allergies = ['cows.milk', 'weeds', 'egg.yolks'];
  const medications = ['Fortecortin (deksametazon) 2 mg tbl - 6mg'];

  let mainCases = patient.medicalCases.filter((mc) => !!mc.active).map((mc) => mc.title).sort();

  const PatientAllergies = () => (<PatientSecondaryInfoList list={allergies} label="allergies" />);
  const PatientMedications = () => (<PatientSecondaryInfoList list={medications} label="medications" />);
  const PatientMainCases = () => (<PatientSecondaryInfoList list={mainCases} label="main.cases" />);

  return (
    <Grid item xs={12} md={4} lg={5}>
      <Grid container spacing={1}>
        {/* <Grid item xs={12} sm={5} md={12} className={classes.xxs5}> */}
        {/*  <Paper elevation={2} className={classes.accordion}> */}
        {/*    <InfoAccordion title={t('allergies')} Item={PatientAllergies} expanded /> */}
        {/*  </Paper> */}
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={7} md={12} className={classes.xxs7}> */}
        {/*  <Paper elevation={2} className={classes.accordion}> */}
        {/*    <InfoAccordion title={t('medications')} Item={PatientMedications} expanded /> */}
        {/*  </Paper> */}
        {/* </Grid> */}
        <Grid item xs={12} sm={8} md={12}>
          <Paper elevation={2} className={classes.accordion}>
            <InfoAccordion title={t('main.cases')} Item={PatientMainCases} />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
