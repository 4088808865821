import React, { useState, forwardRef } from 'react';
import { navigate } from 'gatsby';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Loading from '../Common/Loading';
import { Dropzone } from '../Common/Dropzone/Dropzone';
import { CREATE_MEDICAL_CASE_FILE, CREATE_HOSPITAL_FILE, CREATE_PATIENT_FILE } from '../../graphql/mutations';
import {
  GET_HOSPITAL_FILES_TO_LIST,
  GET_MEDICAL_CASE_FOR_EDITING,
  GET_PATIENT_RELATED_DATA,
} from '../../graphql/queries';
import { AlertUI } from '../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      width: '100%',
      maxWidth: 600,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export const UploadFileDialog = ({ open, onClose, uuid, type = 'hospitalFile', refetch = () => {} }) => {
  const [serverError, setServerError] = useState(false);
  const [file, setFile] = useState('');
  const [disabledButtons, setDisabledButtons] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const getTitle = (option) => {
    switch (option) {
      case 'medicalCaseFile':
        return 'upload.case.file';
      case 'patientFile':
        return 'upload.patient.file';
      default:
        return 'upload.hospital.file';
    }
  };

  const resetUploadForm = () => {
    setFile('');
    setDisabledButtons(false);
    onClose();
  };

  const [createMedicalCaseFile, { loading: updatingMCF }] = useMutation(CREATE_MEDICAL_CASE_FILE, {
    onCompleted() {
      resetUploadForm();
      navigate(`/case/${uuid}`);
    },
    refetchQueries: [{
      query: GET_MEDICAL_CASE_FOR_EDITING,
      variables: { uuid },
      awaitRefetchQueries: true,
    }],
  });
  const [createPatientFile, { loading: updatingPF }] = useMutation(CREATE_PATIENT_FILE, {
    onCompleted() {
      resetUploadForm();
      navigate(`/patient/${uuid}`);
    },
    refetchQueries: [{
      query: GET_PATIENT_RELATED_DATA,
      variables: { uuid },
      awaitRefetchQueries: true,
    }],
  });
  const [createHospitalFile, { loading: updatingHF }] = useMutation(CREATE_HOSPITAL_FILE, {
    onCompleted() {
      resetUploadForm();
      refetch();
      navigate('/files');
    },
    refetchQueries: [{
      query: GET_HOSPITAL_FILES_TO_LIST,
      variables: { hospitalUuid: uuid },
      awaitRefetchQueries: true,
    }],
  });

  const closeModal = () => {
    setServerError(null);
    onClose();
  };

  const handleSubmit = async () => {
    setDisabledButtons(true);
    setServerError(false);
    let input;
    try {
      switch (type) {
        case 'medicalCaseFile':
          input = {
            medicalCaseUuid: uuid,
            file,
          };
          await createMedicalCaseFile({ variables: { input } });
          break;
        case 'patientFile':
          input = {
            patientUuid: uuid,
            file,
          };
          await createPatientFile({ variables: { input } });
          break;
        case 'hospitalFile':
        default:
          input = {
            hospitalUuid: uuid,
            file,
          };
          await createHospitalFile({ variables: { input } });
          break;
      }
    } catch (errors) {
      if (errors.graphQLErrors && errors.graphQLErrors.length) {
        setServerError(errors.graphQLErrors[0].message);
        setDisabledButtons(false);
      }
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
    >
      <DialogTitle>{t(getTitle(type))}</DialogTitle>
      <DialogContent>
        <Dropzone accept="all" setFile={(_file) => setFile(_file)} currentFileUrl={file || ''} />

        {(updatingMCF || updatingPF || updatingHF) && (
          <div className="my-5">
            <Loading />
          </div>
        )}
        {serverError ? <AlertUI severity="error" title="Error">{serverError}</AlertUI> : null}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          disabled={disabledButtons}
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          className={classes.button}
          color="primary"
          disabled={disabledButtons}
          onClick={handleSubmit}
        >
          {t('upload.file')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
