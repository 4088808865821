import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetFormat } from '../utils/functions';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '0 20px',
    paddingBottom: 20,
    fontSize: '0.8em',
  },
  label: {
    color: 'rgba(0,0,0,.4)',
  },
  value: {
    marginLeft: 10,
  },
});

const SplitGroup = (array, size) => [].concat.apply([],
  array.map((elem, index) => (index % size ? [] : [array.slice(index, index + size)]))
);

const ItemCollapse = ({ item, fieldNames }) => {
  const classes = useStyles();
  const groups = SplitGroup(fieldNames.filter((filter) => (filter.width > 0 && !filter.title)), 5);

  return (
    <div className={classes.root}>
      <Grid container>
        {groups.map((group, index1) => (
          <Grid key={index1.toString()} item xs={12} sm={6}>
            {group.map((field, index2) => (
              <div key={index2.toString()}>
                <span className={classes.label}>{`${field.label}:`}</span>
                <span className={classes.value}>{GetFormat(item[field.id], field.format)}</span>
              </div>
            ))}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ItemCollapse;
