import React from 'react';
import i18n from 'i18next';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Grid, Paper } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { GreyLabel } from '../Common/styled/GreyLabel';
import { ByUser } from '../Common/User';
import { getFormattedDate, getYears } from '../../utils/dateTimeUtils';
import { getGenderLabel } from '../../utils/patientGenderList';
import defaultprofilePhoto from '../../images/patient-profile.png';
import InfoAccordion from '../../componentsUI/InfoAccordion';
import { Span } from '../Common/styled/Text';
import { FIND_USER_PROFILE_BY_UUID } from '../../graphql/queries';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '.75em 0',
  },
  box: {
    width: '100%',
    flexGrow: 1,
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  mainTitle: {
    margin: '0 1em',
    color: theme.palette.primary.main,
    '@media (max-width:599px)': {
      margin: '-.5em 0 .5em',
      width: '100%',
      justifyContent: 'center',
      '& h1': {
        margin: '.25em',
      },
    },
  },
  mainInfo: {
    margin: '0 1em .5em',
    '@media (max-width:599px)': {
      margin: '0 1.75em',
    },
  },
  avatarWrapper: {
    margin: '0 0 0 18px',
    '@media (max-width:599px)': {
      margin: '0',
      width: '100%',
      alignItems: 'center',
    },
  },
  avatar: {
    width: '5.5em',
    height: '5.5em',
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: '1em',
    border: '1px #ddd solid',
  },
  initials: {
    width: '92px',
    height: '92px',
    marginTop: '.625em',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: '2.75em',
  },
  keys: {
    color: '#8C8C8C',
    fontSize: '.875em',
  },
  attribute: {
    marginBottom: '.5em',
    fontSize: '.9375em',
  },
  accordion: {
    marginTop: '1em',
    width: '100%',
    '& .MuiAccordionSummary-root': {
      background: theme.palette.primary.dark,
    },
  },
}));

export default ({ patient }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data } = useQuery(
    FIND_USER_PROFILE_BY_UUID,
    {
      variables: {
        uuid: patient.userUuid,
      },
    },
  );

  const profilePhoto = data && data.userByUuid && data.userByUuid.profilePhoto;
  const birthDateTime = patient.birthDate && moment(patient.birthDate).utc().locale(i18n.language);
  const age = getYears(birthDateTime);
  const initials = patient.name.charAt(0) + patient.surname.charAt(0);

  const getNationality = (item) => item && `${item.toUpperCase()} (${t(item)})`;

  const patientAttributes = [
    { id: 'email', label: 'email' },
    { id: 'identityNumber', label: 'identity.number' },
    { id: 'address', label: 'address', optional: true },
    { id: 'phone', label: 'phone', optional: true },
    { id: 'nationality', label: 'nationality', method: getNationality },
    { id: 'insurance', label: 'insurance', optional: true },
    { id: 'insuranceNumber', label: 'insurance.number', optional: true },
  ];

  const PatientAdminItem = () => (
    patientAttributes && patientAttributes.filter((attr) => !!patient[attr.id]).length === 0 ? (
      <Grid container className="user-info-item">
        <Grid item xs={12} className={classes.keys}>
          {t('patient.has.no.personal.data')}
        </Grid>
      </Grid>
    ) : (
      <Grid container direction="column">
        {patientAttributes.map((attr) => (
          (patient[attr.id] || !attr.optional) && (
          <Grid key={attr.id} item xs={12} className="info-item">
            <Grid item className={classes.keys}>
              {t(attr.label)}
            </Grid>
            <Grid item className={classes.attribute}>
              {!attr.method ? patient[attr.id] : attr.method(patient[attr.id])}
            </Grid>
          </Grid>
          )))}
      </Grid>
    )
  );

  const PatientProfileItem = () => (
    <>
      <Grid container className="user-info-item">
        <Grid item xs={12} className={classes.attribute}>
          <Span>
            <GreyLabel>{`${t('patient.file.created.by')}: `}</GreyLabel>
            {patient.createdByData ? <ByUser user={patient.createdByData} /> : <ByUser user={patient.createdBy} />}
          </Span>
        </Grid>
      </Grid>
      <Grid container className="user-info-item">
        <Grid item xs={12} className={classes.attribute}>
          <Span>
            {patient.userUuid ? (
              <GreyLabel>{t('patient.has.access.to.ahp')}</GreyLabel>
            ) : (
              <GreyLabel>{t('patient.not.registered.in.ahp')}</GreyLabel>
            )}
          </Span>
        </Grid>
      </Grid>
      <Grid container className="user-info-item">
        <Grid item xs={12} className={classes.attribute}>
          {patient.invitedBy ? (
            <Span>
              <GreyLabel>{`${t('patient.invited.by')}: `}</GreyLabel>
              <ByUser user={patient.invitedBy} />
            </Span>
          ) : (
            <Span><GreyLabel>{t('patient.not.invited.to.join.ahp')}</GreyLabel></Span>
          )}
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid item xs={12} md={8} lg={7}>
      <Paper elevation={2} className={classes.wrapper}>
        <Box className={classes.box}>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Grid container justifycontent="center" alignItems="center">
                <Grid item className={classes.avatarWrapper}>
                  {profilePhoto ? (
                    <Avatar
                      alt="Patient"
                      className={classes.avatar}
                      src={profilePhoto || defaultprofilePhoto}
                    />
                  ) : (
                    <Avatar className={classes.initials}>{initials}</Avatar>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={9}>

              <Grid container className={classes.mainTitle}>
                <h1>
                  {`${patient.name} ${patient.surname}`}
                </h1>
              </Grid>

              <Grid container direction="column" className={classes.mainInfo}>
                <Grid item className={classes.keys}>
                  {`${t('birth.date')}:`}
                </Grid>
                <Grid item className={classes.attribute}>
                  <span>
                    {patient && getFormattedDate({ date: patient.birthDate })}
                    <small><strong>{` (${t('age')} ${age} ${t('years')})`}</strong></small>
                  </span>
                </Grid>
              </Grid>

              <Grid container direction="column" className={classes.mainInfo}>
                <Grid item className={classes.keys}>
                  {`${t('gender')}:`}
                </Grid>
                <Grid item className={classes.attribute}>
                  {t(getGenderLabel(patient.gender))}
                </Grid>
              </Grid>

            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item className={classes.accordion}>
              <InfoAccordion title={t('personal.data')} Item={PatientAdminItem} />
              <InfoAccordion title={t('user.info')} Item={PatientProfileItem} />
            </Grid>
          </Grid>

        </Box>
      </Paper>
    </Grid>
  );
};
