import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Collapse, IconButton, Tooltip, Zoom } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNewOutlined';
import { useStyles } from './styles/ArchivesTableCollapse';
import { ItemFieldsResponsive } from './tableCollapseItems';

const ArchivesTableCollapse = (props) => {
  const { t } = useTranslation();
  const { fieldNames, items, ActionButtons, GetTitle, GetSubtitle, GetCollapse } = props;
  const { selected, setSelected, onDoubleClick, handleGoto } = props;
  const { order, handleOrder } = props;

  const classes = useStyles();
  const [timer, setTimer] = useState();

  const handleSelect = (id) => (evt) => {
    evt.stopPropagation();

    if (!timer) {
      if (setSelected) {
        if (selected.includes(id)) {
          setSelected(selected.filter((item) => item !== id));
        } else {
          setSelected([id]);
        }
      }
      setTimer(setTimeout(() => {
        setTimer();
      }, 250));
    } else {
      if (onDoubleClick) onDoubleClick(id);
      clearTimeout(timer);
      setTimer();
    }
  };

  const childrenProps = { classes, fieldNames, GetTitle, GetSubtitle, handleGoto, order, handleOrder };
  const IconButtons = ActionButtons || [{
    Icon: OpenInNewIcon,
    tooltip: 'open',
    onClick: handleGoto,
  }];

  const isButtonDisabled = (button, item) => button.disabled && button.disabled(item);

  return (
    <Box className={classes.table}>
      {items.map((item, index) => (
        <div key={index.toString()} className={selected.includes(index) ? classes.itemSelected : classes.item} onClick={handleSelect(index)} aria-hidden="true">
          <div className={classes.tableItem}>
            <div style={{ width: 8 }} />
            <div style={{ display: 'flex', width: '100%' }}>
              <ItemFieldsResponsive {...childrenProps} index={index} item={item} />
              {!!handleGoto
              && (
              <div className="ml-auto">
                {IconButtons && IconButtons.map((Button, i) => !isButtonDisabled(Button, item) && (
                  <Tooltip key={i.toString()} title={t(Button.tooltip)} TransitionComponent={Zoom} placement="bottom" arrow>
                    <IconButton
                      className={classes.goButton}
                      onClick={(evt) => Button.onClick(evt, index)}
                    >
                      <Button.Icon />
                    </IconButton>
                  </Tooltip>
                ))}
              </div>
              )}
            </div>
          </div>

          <Collapse className={classes.collapse} in={selected.includes(index)} timeout="auto" unmountOnExit>
            <div style={{ display: 'flex' }}>
              {GetCollapse && <GetCollapse item={item} fieldNames={fieldNames} /> }
            </div>
          </Collapse>
        </div>
      ))}
    </Box>
  );
};

export default ArchivesTableCollapse;
