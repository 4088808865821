import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Layout from '../../components/MenuLayout';
import CaseDetails from '../../components/Case/CaseDetails';
import CasePatientDetails from '../../components/Case/CasePatientDetails';
import Loading from '../../components/Common/Loading';
import { GET_MEDICAL_CASE_FOR_EDITING } from '../../graphql/queries';
import { getUserInterface } from '../../utils/patientUserHospitalUtils';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';

export default ({ caseId }) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const { loading, error, data, refetch } = useQuery(
    GET_MEDICAL_CASE_FOR_EDITING,
    {
      variables: { uuid: caseId },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const userInterface = getUserInterface();
  const CaseComponent = userInterface === 'patient' ? CasePatientDetails : CaseDetails;
  const medicalCase = data && data.medicalCase;

  if (!errorMessage && error) setErrorMessage(networkErrorParse(error));

  return (
    <Layout>
      {
        loading && !medicalCase && !errorMessage
          ? <Loading />
          : <CaseComponent medicalCase={medicalCase} refetch={refetch} errorMessage={errorMessage} />
      }
    </Layout>
  );
};
