import React from 'react';

import Layout from '../../components/MenuLayout';
import { DashboardView } from '../../components/DashboardView';
import { PatientDashboardView } from '../../components/PatientDashboardView';
import { getUserInterface } from '../../utils/patientUserHospitalUtils';

const userInterface = getUserInterface();

export default () => (
  <Layout>
    {userInterface === 'patient' ? <PatientDashboardView /> : <DashboardView />}
  </Layout>
);
