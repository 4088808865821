import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { Container, Grid, Paper, Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, sortGraphQueryList } from '../utils/ObjectUtils';
import Loading from './Common/Loading';
import { SectionBar } from '../componentsUI/SectionBar';

import { GET_PATIENT_MEDICAL_CASES } from '../graphql/queries';
import { GET_PATIENT_VIRTUAL_VISITS } from '../queries/VirtualVisits/VirtualVisits';
import TableCollapse from '../componentsUI/tableCollapse';
import { CaseCollapse } from './Case/CaseCollapse';
import { GetFormat } from '../utils/functions';
import { Navbar } from './Navbar/styled/NavbarStyles';
import { ContainerUI } from './Common/styled/ContainerUI';
import ScrollableContainer from './Common/ScrollableContainer';
import { networkErrorParse } from '../utils/ErrorGraphQLUtils';
import { AlertWrapperUI, GraphQLAlertUI } from '../componentsUI/Alert';

export const useStyles = makeStyles((theme) => ({
  title: {
    margin: '1em .375em -.75em',
    padding: '.25em .625em',
    '& > p': {
      fontSize: '1.25em',
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
  },
}));

const fieldNames = [
  { label: 'medical.case', id: 'title', field: 'TITLE', direction: 'ASC', width: 370 },
  { label: 'case.created', id: 'createdAt', field: 'CREATED_AT', direction: 'DESC', width: 120, format: 'DATEFORMAT' },
];

export const PatientDashboardView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedCase, setSelectedCase] = useState([]);
  const [selectedVisit, setSelectedVisit] = useState([]);

  const selectVisit = (array) => {
    setSelectedCase([]);
    setSelectedVisit(array);
  };

  const selectCase = (array) => {
    setSelectedVisit([]);
    setSelectedCase(array);
  };

  const orderByField = 'CREATED_AT';
  const orderByDirection = 'DESC';

  const user = useSelector((state) => state.userInterface.user);
  const { loading: mcLoading, error: mcError, data: mcData, refetch: mcRefetch } = useQuery(
    GET_PATIENT_MEDICAL_CASES, {
      variables: {
        uuid: user.patientUuid,
        first: 20,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const { loading: vvLoading, error: vvError, data: vvData, refetch: vvRefetch } = useQuery(
    GET_PATIENT_VIRTUAL_VISITS, {
      variables: {
        patientUuid: user.patientUuid,
        first: 20,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const errorMessage = networkErrorParse(mcError) || networkErrorParse(vvError);

  const handleRefetch = () => {
    mcRefetch();
    vvRefetch();
  };

  const medicalCases = mcData && mcData.patient && mcData.patient.medicalCases
    && mcData.patient.medicalCases.length > 0 && sortGraphQueryList(
    mcData.patient.medicalCases,
    fieldNames,
    orderByField,
    orderByDirection,
  ).slice(0, 5);

  const virtualVisits = vvData && vvData.virtualVisits && vvData.virtualVisits.edges
    && vvData.virtualVisits.edges.length > 0 && sortGraphQueryList(
    vvData.virtualVisits.edges,
    fieldNames,
    orderByField,
    orderByDirection,
  ).slice(0, 5);

  const handleGotoCase = (evt, index) => {
    evt.stopPropagation();
    const indexCase = medicalCases[index];

    navigate(`/case/${indexCase.uuid}`);
  };

  const handleGotoVisit = (evt, index) => {
    evt.stopPropagation();
    const indexVisit = virtualVisits[index].node;

    navigate(`/virtual-visit/${indexVisit.uuid}`);
  };

  const getCaseTitle = (item) => (
    `${item.title}`
  );

  const getCaseSubTitle = (item) => (
    `${GetFormat(item.createdAt, 'DATEFORMAT')} - ${GetFormat(item.active, 'ACTIVECASE')}`
  );

  const getVisitTitle = (item) => {
    const doctor = item.node && item.node.doctor.fullname;
    const medicalCase = item.node && item.node.medicalCase && item.node.medicalCase.title;

    return `${t('doctor')}: ${doctor} | ${medicalCase}`;
  };

  const getVisitSubTitle = (item) => {
    const answered = item.node && item.node.answered;

    if (answered) return t('reply.completed');
    return `${t('appointment')}: ${GetFormat(item.node.appointment, 'DATEFORMAT')}`
  };

  const medicalCase = medicalCases && medicalCases[selectedCase[0]];
  const virtualVisit = virtualVisits && virtualVisits[selectedVisit[0]] && virtualVisits[selectedVisit[0]].node;

  const handleOpen = (evt) => {
    evt.stopPropagation();

    if (medicalCase) {
      navigate(`/case/${medicalCase.uuid}`);
    }

    if (virtualVisit) {
      navigate(`/virtual-visit/${virtualVisit.uuid}`);
    }
  };

  const openButtonDisabled = selectedCase.length !== 1 && selectedVisit.length !== 1;
  const buttons = [
    { name: 'open', icon: OpenInNew, handleClick: handleOpen, disabled: openButtonDisabled },
  ];

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <GraphQLAlertUI severity="warning" title="Error" refetch={handleRefetch}>
          {t(errorMessage)}
        </GraphQLAlertUI>
      </AlertWrapperUI>
    );
  }

  return (
    <ContainerUI>
      <Navbar>
        <SectionBar title="recent.patient.data.in.hospital" items={buttons} />
      </Navbar>
      <Container maxWidth="lg" className="article">
        <ScrollableContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper elevation={2} className={classes.title}>
                <Typography>
                  {t('cases')}
                </Typography>
              </Paper>
              { mcLoading && isEmpty(mcData) && <Loading /> }
              { medicalCases
              && (
                <TableCollapse
                  responsive={false}
                  fieldNames={fieldNames}
                  items={medicalCases}
                  GetTitle={getCaseTitle}
                  GetSubtitle={getCaseSubTitle}
                  GetCollapse={CaseCollapse}
                  selected={selectedCase}
                  setSelected={selectCase}
                  handleGoto={handleGotoCase}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={2} className={classes.title}>
                <Typography>
                  {t('virtual.visits')}
                </Typography>
              </Paper>
              { vvLoading && isEmpty(vvData) && <Loading /> }
              { virtualVisits
              && (
                <TableCollapse
                  responsive={false}
                  fieldNames={fieldNames}
                  items={virtualVisits}
                  GetTitle={getVisitTitle}
                  GetSubtitle={getVisitSubTitle}
                  selected={selectedVisit}
                  setSelected={selectVisit}
                  handleGoto={handleGotoVisit}
                />
              )}
            </Grid>
          </Grid>
        </ScrollableContainer>
      </Container>
    </ContainerUI>
  );
};
